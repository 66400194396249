import React, { useContext, useEffect, useState } from 'react'

import { Pane, Button, Select, Strong, Text, Paragraph, TextInput, Heading } from 'evergreen-ui'

import { ErrorInline, errorToast } from './Errors'
import { OverlaySpinner } from './OverlaySpinner'
import { ConfigContext } from '../context/ConfigState'
import { ProvidersContext } from '../context/ProvidersState'
import { ItineraryContext } from '../context/ItineraryState'
import { fireTagManagerEvent } from 'src/helpers'

// General detection of start steps follow:
// 1) on start:
//     providersContext.providersConfig.region === ''
//     providersContext.providersConfig.startAddres === ''
//     providersContext.providersConfig.startLocation === ''
// 2) when region is chose, ...region !== ''
// 3) when location entered and search button clicked, ...startAddress !== ''
// 4) when location confirmed, ...startLocation !== ''
// ... standard full inteface can be loaded now

export const Start = () => {
  console.log('> Start')
  const { providerItems, providersConfig, clearProviders } = useContext(ProvidersContext)
  const { configStatus } = useContext(ConfigContext)

  if (configStatus === 'loading') return <OverlaySpinner />
  if (!configStatus) return <ErrorInline />
  if (configStatus.error) return <ErrorInline error={configStatus.errObj} />

  return (
    <Pane flex={1} padding={16} maxWidth={1200}>
      {providerItems.region === '' || providersConfig.startLocation === '' ? (
        <div id="appstart">
          <RegionSelect />
          <StartLocation />
        </div>
      ) : (
        <Pane className="flex" justifyContent="space-between">
          <Pane display="flex" flexWrap="wrap">
            <Text marginRight={16}>
              <Strong>Regija:</Strong> {providersConfig.region}
            </Text>
            <Text marginRight={16}>
              <Strong>Start:</Strong> {providersConfig.startAddress}
            </Text>
          </Pane>
          <Button
            onClick={clearProviders}
            minWidth={200}
            marginLeft={16}
            intent="danger"
            size="large"
          >
            Obriši i kreni ispočetka
          </Button>
        </Pane>
      )}
    </Pane>
  )
}

const RegionSelect = () => {
  console.log('> RegionSelect')
  const { providersConfig, providersRegions, regionsStatus, updateRegion, fetchRegions } =
    useContext(ProvidersContext)

  useEffect(() => {
    fetchRegions().then((regions) => {
      updateRegion(regions[0])
    })
  }, [])

  if (providersConfig.startAddress !== '') return null //hide region selector when confirming location found
  if (regionsStatus.error)
    return <ErrorInline retry={fetchRegions} error={regionsStatus.errObj} />

  const handleChange = (e) => {
    updateRegion(e.target.value)
  }

  return (
    <Pane padding={16} margin="auto" position="relative">
      {regionsStatus === 'loading' ? <OverlaySpinner /> : null}
      <Heading size={600} marginBottom={4} textAlign="left">
        Odaberite regiju izleta:
      </Heading>
      <Select
        value={providersConfig.region}
        onChange={handleChange}
        background="white"
        paddingBottom={8}
        size="large"
      >
        {providersRegions
          .sort((a, b) => a.localeCompare(b))
          .reverse()
          .map((region) => (
            <option key={region} value={region}>
              {region}
            </option>
          ))}
      </Select>
    </Pane>
  )
}

const StartLocation = () => {
  console.log('> StartLocation')

  const { geolocatePlace, updateStartLocation, providersConfig } = useContext(ProvidersContext)
  const { startItinerary } = useContext(ItineraryContext)
  const [address, setAddress] = React.useState('')
  const [location, setLocation] = React.useState('')
  const [status, setStatus] = useState('')

  const queryLocation = () => {
    setStatus('loading')
    return geolocatePlace(address)
      .then((location) => {
        if (location.error) throw location.error
        return location
      })
      .then(setLocation)
      .then(() => updateStartLocation(address, ''))
      .then(() => {
        setStatus('ok')
      })
      .catch(() => {
        errorToast('Greška kod traženja lokacije!', 'provjerite adresu i pokušajte ponovo.')
        setStatus('')
      })
  }

  const confirmLocation = () => {
    fireTagManagerEvent({
      event: 'sendCity',
      city: location.city,
      district: providersConfig.region,
      urlDestination: window.izlethr_landing_url
    })
    startItinerary({ location: location.coords, name: location.address })
    updateStartLocation(location.address, location.coords, location.city)

  }

  const reset = () => {
    setLocation('')
    setAddress('')
    updateStartLocation('', '', '')
    setStatus('')
  }

  return (
    <Pane>
      {!location ? (
        <Pane width="100%"
          padding={16}
          margin="auto"
          display="flex"
          flexDirection="column"
        >
          <Heading size={600} marginBottom={4} textAlign="left">
            Adresa ili lokacija polaska:
          </Heading>
          <TextInput
            width="100%"
            name="startAddress"
            placeholder="adresa ili naziv lokacije polaska"
            onChange={(e) => setAddress(e.target.value)}
            marginBottom={8}
            size="large"
          />
          <Paragraph width="90%" marginBottom={16} color="muted">
            Za kalkulaciju itinerara, potrebno je unijeti točku polaska na mapi. Najpreciznije je
            unijeti točnu ulicu i broj, te grad odvojen zarezom (npr.: <i>Doverska 44, Split</i>).
            Druga opcija, iako manje precizna, je ukucati ime točke i grad odvojen zarezom. Npr.:{' '}
            <i>Škola Mertojak, Split</i>
          </Paragraph>
          <Button
            width="100%"
            appearance="primary"
            marginTop={8}
            size="large"
            isLoading={status === 'loading'}
            disabled={address === '' || providersConfig.region === ''}
            onClick={queryLocation}
          >
            Pronađi unesenu lokaciju...
          </Button>
        </Pane>
      ) : (
        <Pane padding={16} margin="auto">
          <Paragraph size={500}>Naš geolokacijski sustav je pronašao lokaciju:</Paragraph>
          <Heading size={500} margin={32}>
            <i>{location.address}</i>
          </Heading>
          <Paragraph marginBottom={16} color="muted">
            Ukoliko je adresa (ili lokacija) ispravna, molimo potvrdite. U suprotnom, kliknite na
            &quot;Ponovi unos&quot; i unesite neku drugu lokaciju/adresu.
          </Paragraph>
          <Pane className="flex" justifyContent="space-between">
            <Button onClick={confirmLocation} marginRight={16} marginY={16} appearance="primary" intent="primary" size="large">
              Klikni ako je lokacija ispravna
            </Button>
            <Button onClick={reset} marginY={16} intent="danger" size="large">
              Ponovi unos
            </Button>
          </Pane>
        </Pane>
      )}
    </Pane>
  )
}
