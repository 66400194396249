import React, { useRef, useState, useEffect } from 'react'
import { Pane } from 'evergreen-ui'
import config from '../config'
import { makeid } from '../helpers'

export const Banner = ({ zoneName, counties, target, reloadWhenChanged, reloadEverySeconds, debounce = 2000, width = '100%' }) => {
  console.log('> Banner', zoneName, width)

  const zone = config.adServer.adZones[zoneName] || null
  if (!zone) return null
  if (!counties) counties = ''
  const cb = makeid(8)
  const bannerPane = useRef(null)
  const bannerImage = useRef(null)
  const [reload, setReload] = useState(null)

  const onImgLoad = ({ target: img }) => {
    const { naturalHeight } = img
    if (naturalHeight == 1) {
      img.style.height = '1px'
      img.style.width = '1px'
      img.style.display = 'none'
      bannerPane.current.style.display = 'none'
    } else {
      img.style.width = width
      img.style.display = 'block'
      bannerPane.current.style.display = 'block'
    }
  }

  const src = config.adServer.url + '/www/delivery/avw.php?n=' + zone.n + '&cb=' + cb + '&zoneid=' + zone.id + '&county=' + counties
  const href = config.adServer.url + '/www/delivery/ck.php?n=' + zone.n + '&cb=' + cb

  useEffect(() => {
    const debounceRender = setTimeout(() => {
      bannerImage.current.src = src
    }, debounce)

    return () => clearTimeout(debounceRender)
  }, [reloadWhenChanged, reload])

  useEffect(() => {
    if (!reloadEverySeconds) return
    console.log('banner reload timer started')
    const reloadTimer = setTimeout(() => {
      console.log('banner reload timer ended')
      setReload(makeid(3))
    }, reloadEverySeconds*1000)

    return () => clearTimeout(reloadTimer)
  },[reload])
  //TODO: dynamic aspect-ratio!!! below one is a test and will break pages!
  return (
    <Pane padding={0} margin={0} style={{aspectRatio: zone.ar}}>
      <a ref={bannerPane} href={href} target={target} style={{display: 'none'}}>
        <img ref={bannerImage} onLoad={onImgLoad} style={{display: 'none'}} src='' border='0' alt='Oglas' width="100%" height="100%" />
      </a>
    </Pane>
  )
}
