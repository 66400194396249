import { v4 as uuidv4 } from 'uuid'

export default function itineraryReducer(state, action) {
  switch (action.type) {
    case 'LOADING_ITINERARY':
      return {
        ...state,
        itineraryStatus: 'loading',
      }

    case 'OK_ITINERARY':
      return {
        ...state,
        itineraryStatus: 'ok',
      }

    case 'START_ITINERARY':
      return {
        ...state,
        itineraryStatus: 'ok',
        itineraryItems: [
          {
            id: uuidv4(),
            type: 'start',
            data: { location: action.payload.location, name: action.payload.name },
          },
          {
            id: uuidv4(),
            type: 'transport',
            data: { from: action.payload.location, to: action.payload.location },
          },
          {
            id: uuidv4(),
            type: 'end',
            data: { location: action.payload.location, name: action.payload.name },
          },
        ],
      }

    case 'UPDATE_ITINERARY_ITEMS':
      return {
        ...state,
        itineraryStatus: 'ok',
        itineraryItems: action.payload,
      }

    case 'SET_ITINERARY_TRIPDATE':
      return {
        ...state,
        itineraryConfig: { ...state.itineraryConfig, tripDate: action.payload },
      }

    case 'SET_ITINERARY_PERSONS':
      return {
        ...state,
        itineraryConfig: { ...state.itineraryConfig, persons: action.payload },
      }

    case 'SET_ITINERARY_GRATIS':
      return {
        ...state,
        itineraryConfig: { ...state.itineraryConfig, gratis: action.payload },
      }

    case 'SET_ITINERARY_TEACHERS':
      return {
        ...state,
        itineraryConfig: { ...state.itineraryConfig, teachers: action.payload },
      }

    case 'SET_ITINERARY_PEDAGOGICAL':
      return {
        ...state,
        itineraryConfig: { ...state.itineraryConfig, pedagogical: action.payload },
      }

    case 'SET_CLIENT_DATA':
      return {
        ...state,
        clientData: { ...action.payload },
      }

    case 'LOAD_PACKAGE_DATA':
      return {
        ...state,
        itineraryStatus: 'ok',
        itineraryConfig: { ...action.config },
        itineraryItems: action.itineraryItems,
      }

    default:
      return state
  }
}
