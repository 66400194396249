export default function providersReducer(state, action) {
  switch (action.type) {
    case 'LOADING_PROVIDERS':
      return {
        ...state,
        providersStatus: 'loading',
      }

    case 'LOADING_REGIONS':
      return {
        ...state,
        regionsStatus: 'loading',
      }

    case 'LOADING_COUNTIES':
      return {
        ...state,
        countiesStatus: 'loading',
      }

    case 'PROVIDERS_ERROR':
      return {
        ...state,
        providersStatus: { error: true, errObj: action.payload },
      }

    case 'REGIONS_ERROR':
      return {
        ...state,
        regionsStatus: { error: true, errObj: action.payload },
      }

    case 'COUNTIES_ERROR':
      return {
        ...state,
        countiesStatus: { error: true, errObj: action.payload },
      }

    case 'UPDATE_PROVIDERS':
      return {
        ...state,
        providersStatus: 'ok',
        providerItems: action.payload,
      }

    case 'UPDATE_REGION':
      return {
        ...state,
        providersConfig: { ...state.providersConfig, region: action.region },
      }

    case 'UPDATE_REGIONS': //First region selected by default
      return {
        ...state,
        regionsStatus: 'ok',
        providersRegions: action.payload,
        providersConfig: { ...state.providersConfig, region: action.payload[0] },
      }

    case 'UPDATE_COUNTIES':
      return {
        ...state,
        countiesStatus: 'ok',
        providersCounties: action.payload,
      }

    case 'UPDATE_CATEGORY_ADD':
      return {
        ...state,
        providersConfig: {
          ...state.providersConfig,
          category: [...state.providersConfig.category, action.category],
        },
      }

    case 'UPDATE_CATEGORY_SELECT_ALL':
      return {
        ...state,
        providersConfig: {
          ...state.providersConfig,
          category: action.categories,
        },
      }

    case 'UPDATE_CATEGORY_REMOVE':
      const idx = state.providersConfig.category.findIndex((i) => i === action.category) //eslint-disable-line
      if (idx < 0) return state
      return {
        ...state,
        providersConfig: {
          ...state.providersConfig,
          category: state.providersConfig.category
            .slice(0, idx)
            .concat(state.providersConfig.category.slice(idx + 1)),
        },
      }

    case 'UPDATE_COUNTY_ADD':
      return {
        ...state,
        providersConfig: {
          ...state.providersConfig,
          county: [...state.providersConfig.county, action.county],
        },
      }

    case 'UPDATE_COUNTY_SELECT_ALL':
      return {
        ...state,
        providersConfig: {
          ...state.providersConfig,
          county: action.activeCounties,
        },
      }

    case 'UPDATE_COUNTY_REMOVE':
      const cidx = state.providersConfig.county.findIndex((i) => i === action.county) //eslint-disable-line
      if (cidx < 0) return state
      return {
        ...state,
        providersConfig: {
          ...state.providersConfig,
          county: state.providersConfig.county
            .slice(0, cidx)
            .concat(state.providersConfig.county.slice(cidx + 1)),
        },
      }

    case 'UPDATE_START_LOCATION':
      return {
        ...state,
        providersConfig: {
          ...state.providersConfig,
          startAddress: action.payload.startAddress,
          startCity: action.payload.startCity,
          startLocation: action.payload.startLocation,

        },
      }

    case 'CLEAR_PROVIDERS':
      return {
        ...state,
        providersStatus: 'loading',
        providersConfig: { region: '', startAddress: '', startCity: '', startLocation: '', category: [], county: [] },
        providerItems: [],
      }

    case 'LOAD_PACKAGE_DATA':
      return {
        ...state,
        providersConfig: { ...action.data },
      }

    case 'SET_HEIGHT':
      return {
        ...state,
        containerHeight: action.payload
      }

    default:
      return state
  }
}
