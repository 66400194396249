import React, { createContext, useReducer } from 'react'

import config from '../config'
import configReducer from './ConfigReducer.js'

const initialState = {
  status: 'loading',
  transport: [],
  general: {},
  categories: {},
}

export const ConfigContext = createContext(initialState)

export const ConfigProvider = ({ children }) => {
  const [state, dispatch] = useReducer(configReducer, initialState)

  const fetchConfig = () => {
    console.log('CONFIG getting from DB...')
    dispatch({ type: 'LOADING_CONFIG' })
    fetch(`${config.backend.API_URL}/config`)
      .then((response) => {
        if (!response.ok) throw new Error('error fetching config')
        return response.json()
      })
      .then((data) => {
        dispatch({ type: 'UPDATE_CONFIG', payload: data })
        if (window.location.pathname === '/i') alert(window.location.pathname)
        console.log('loaded config:', data)
      })
      .catch((error) => {
        console.log('ERROR! ', error)
        dispatch({ type: 'ERROR', payload: error })
      })
  }

  const fetchPackage = (id) => {
    console.log('CONFIG PACKAGE getting from DB...', id)
    return fetch(`${config.backend.API_URL}/saved?id=${id}`).then((response) => {
      if (!response.ok) throw new Error('error fetching package')
      return response.json()
    })
  }

  React.useEffect(fetchConfig, [])

  return (
    <ConfigContext.Provider
      value={{
        transport: state.transport,
        categories: state.categories,
        general: state.general,
        configStatus: state.status,
        fetchConfig,
        fetchPackage,
      }}
    >
      {children}
    </ConfigContext.Provider>
  )
}
