import React, { createContext, useReducer } from 'react'

const initialState = {
  draggingItem: false,
}

export const DraggableContext = createContext(initialState)

export const DraggableProvider = ({ children }) => {
  const [state, dispatch] = useReducer(draggableReducer, initialState)

  const setDraggingItem = (itemId) => {
    console.log('DEBUG setting dragging item to: ', itemId)
    dispatch({ type: 'SET_DRAGGING_ITEM', draggingItem: itemId })
  }

  return (
    <DraggableContext.Provider
      value={{
        draggingItem: state.draggingItem,
        setDraggingItem,
      }}
    >
      {children}
    </DraggableContext.Provider>
  )
}

const draggableReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DRAGGING_ITEM':
      return {
        ...state,
        draggingItem: action.draggingItem,
      }

    default:
      return state
  }
}
