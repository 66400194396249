import React, { createContext, useReducer } from 'react'
import { v4 as uuidv4 } from 'uuid'

import config from '../config'
import itineraryReducer from './ItineraryReducer'

const initialState = {
  itineraryStatus: 'loading',
  itineraryItems: [],
  itineraryConfig: {
    tripDate: new Date(Date.now() + 12096e5).toISOString().split('T')[0], //today + 2 weeks
    persons: 15,
    gratis: 0,
    teachers: 1,
    pedagogical: false,
  },
  clientData: {
    type: '',
    name: '',
    contact: '',
    function: '',
    phone: '',
    email: '',
    city: '',
  },
}

const fetchRoute = (params) => {
  console.log('fetching route calculation...')

  //TODO: move API addr to config
  return fetch(`${config.backend.API_URL}/route?
			origin=${encodeURIComponent(params.origin)}
			&destination=${encodeURIComponent(params.destination)}
			&vehicleCategory=${encodeURIComponent(params.vehicleCategory)}
			&departureTime=${encodeURIComponent(params.departureTime)}
		`).then((response) => {
    if (!response.ok) throw new Error('Error fetching route calculation')
    return response.json()
  })
}

const fetchRouteVia = (params) => {
  console.log('fetching route with multiple points calculation...', params)
  //TODO: move API addr to config
  return fetch(`${config.backend.API_URL}/routevia`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  }).then((response) => {
    if (!response.ok) throw new Error('Error fetching route multipoints calculation')
    return response.json()
  })
}

export const ItineraryContext = createContext(initialState)

export const ItineraryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(itineraryReducer, initialState)

  //
  // Public functions used in components
  //

  const insertItineraryItemToPos = (newItem, pos, list) => {
    newItem.id = newItem.id || uuidv4()
    console.log('Adding item to array pos position ', pos)
    const prevItem = list[pos - 1]
    const lunch = list[pos].data.lunch
    const breakItem = list[pos].data.break
    const nextItem = list[pos + 1]
    const remainingItems = [...list.slice(pos + 1)]

    const items = [...list.slice(0, pos)]

    if (!newItem.location && (!newItem.data || !newItem.data.location))
      return Promise.reject('no location to use for search')

    return fetchRoute({
      origin: prevItem.data.location,
      destination: newItem.location || newItem.data.location,
      vehicleCategory: 'III', //NOTE: we calculate other categories based on discount from cat III
      departureTime: '2021-01-01T21:00:00', //NOTE: Fixed date as Here seems to return empty prices for newer dates
    })
      .then((route) => {
        items.push({
          id: uuidv4(),
          type: route.walking ? 'walking' : 'transport',
          data: {
            from: prevItem.data.location,
            to: newItem.location || newItem.data.location,
            totalTolls: route.summary.totalTolls,
            length: route.summary.length,
          },
        })

        if (newItem.data) {
          items.push(newItem)
        } else {
          items.push({ id: uuidv4(), type: 'point', data: newItem })
        }

        return fetchRoute({
          origin: newItem.location || newItem.data.location,
          destination: nextItem.data.location,
          vehicleCategory: 'III',
          departureTime: '2021-01-01T21:00:00',
        })
      })
      .then((route) => {
        items.push({
          id: uuidv4(),
          type: route.walking ? 'walking' : 'transport',
          data: {
            from: newItem.location || newItem.data.location,
            to: nextItem.data.location,
            totalTolls: route.summary.totalTolls,
            length: route.summary.length,
            lunch: lunch,
            break: breakItem,
          },
        })
        return items.concat(remainingItems)
      })
  }

  const deleteItineraryItemAtPos = (idx, list) => {
    const prevItem = list[idx - 2]
    const lunch = list[idx - 1].data.lunch || list[idx + 1].data.lunch
    const breakItem = list[idx - 1].data.break || list[idx + 1].data.break
    const nextItem = list[idx + 2]
    console.log('deleteing itinerary item at pos ', idx, ' of the list:', list, prevItem, nextItem)

    const items = [...list.slice(0, idx - 1)]

    return fetchRoute({
      origin: prevItem.data.location,
      destination: nextItem.data.location,
      vehicleCategory: 'III', // NOTE: we hardcode category as other category prices are based on this one
      departureTime: '2021-01-01T21:00:00',
    }).then((route) => {
      items.push({
        id: uuidv4(),
        type: route.walking ? 'walking' : 'transport',
        data: {
          from: prevItem.data.location,
          to: nextItem.data.location,
          totalTolls: route.summary.totalTolls,
          length: route.summary.length,
          lunch: lunch,
          break: breakItem,
        },
      })
      return items.concat(list.slice(idx + 2))
    })
  }

  //
  // Public functions used in components
  //

  const startItinerary = (startLocation) => {
    dispatch({
      type: 'START_ITINERARY',
      payload: startLocation,
    })
  }

  const recalculateAllRoutes = (itineraryItems) => {
    const params = {
      origin: itineraryItems[0].data.location,
      via: itineraryItems.filter((i) => i.type === 'point').map((p) => p.data.location),
      destination: itineraryItems[itineraryItems.length - 1].data.location,
      vehicleCategory: 'III', //NOTE: we calculate other categories based on discount from cat III
      departureTime: '2021-01-01T21:00:00', //NOTE: Fixed date as Here seems to return empty prices for newer dates
    }

    return fetchRouteVia(params).then((travelPoints) => {
      console.log('DEBUG got travelPoints', travelPoints)
      if (travelPoints.error) throw new Error('Error fetching route data from map provider')
      if (travelPoints.length !== (itineraryItems.length - 1) / 2)
        throw new Error('Error fetching route data from map provider. Points number mismatch')

      // update itinerary items with recalculated data between points
      itineraryItems = itineraryItems.map((i) => {
        if (i.type === 'transport' || i.type === 'walking') {
          const t = travelPoints.shift()
          return {
            ...i,
            type: t.walking ? 'walking' : 'transport',
            data: {
              from: t.from,
              to: t.to,
              totalTolls: t.summary.totalTolls,
              length: t.summary['length'],
              lunch: i.data.lunch,
              break: i.data.break,
            },
          }
        } else {
          return i
        }
      })
      console.log('DEBUG complete recalculated itinerary', itineraryItems)
      return itineraryItems
    })
  }

  const addItineraryItem = (newItem, pos) => {
    pos = pos || state.itineraryItems.length - 2
    dispatch({ type: 'LOADING_ITINERARY' })

    return insertItineraryItemToPos(newItem, pos, state.itineraryItems)
      .then((calculatedItems) => {
        console.log('calculatedItems: ', calculatedItems)

        dispatch({
          type: 'UPDATE_ITINERARY_ITEMS',
          payload: calculatedItems,
        })
      })
      .catch((error) => {
        dispatch({
          type: 'OK_ITINERARY',
        })
        throw error
      })
  }

  const setLunchItem = (pos) => {
    pos = pos || state.itineraryItems.length - 2
    const newItineraryItems = [...state.itineraryItems]
    // delete old lunch if found
    const idx = newItineraryItems.findIndex((i) => i.data.lunch)
    if (idx >= 0) newItineraryItems[idx].data.lunch = false
    // add new lunch
    newItineraryItems[pos].data.lunch = true
    dispatch({ type: 'UPDATE_ITINERARY_ITEMS', payload: newItineraryItems })
  }

  const unsetLunchItem = () => {
    // delete old lunch if found
    const newItineraryItems = [...state.itineraryItems]
    const idx = newItineraryItems.findIndex((i) => i.data.lunch)
    if (idx >= 0) newItineraryItems[idx].data.lunch = false
    dispatch({ type: 'UPDATE_ITINERARY_ITEMS', payload: newItineraryItems })
  }

  const setBreakItem = (pos) => {
    pos = pos || state.itineraryItems.length - 2
    const newItineraryItems = [...state.itineraryItems]
    // delete old break if found
    const idx = newItineraryItems.findIndex((i) => i.data.break)
    if (idx >= 0) newItineraryItems[idx].data.break = false
    // add new break
    newItineraryItems[pos].data.break = true
    dispatch({ type: 'UPDATE_ITINERARY_ITEMS', payload: newItineraryItems })
  }

  const unsetBreakItem = () => {
    // delete old break if found
    const newItineraryItems = [...state.itineraryItems]
    const idx = newItineraryItems.findIndex((i) => i.data.break)
    if (idx >= 0) newItineraryItems[idx].data.break = false
    dispatch({ type: 'UPDATE_ITINERARY_ITEMS', payload: newItineraryItems })
  }

  const removeItineraryItem = (id) => {
    dispatch({ type: 'LOADING_ITINERARY' })

    const idx = state.itineraryItems.findIndex((item) => item.id === id)
    if (idx < 0) return Promise.reject('Itinerary item not found')
    if (state.itineraryItems.length === 5) {
      dispatch({ type: 'START_ITINERARY', payload: state.itineraryItems[0].data })
      return Promise.resolve()
    }

    return deleteItineraryItemAtPos(idx, state.itineraryItems)
      .then((calculatedItems) => {
        dispatch({
          type: 'UPDATE_ITINERARY_ITEMS',
          payload: calculatedItems,
        })
      })
      .catch((error) => {
        dispatch({
          type: 'OK_ITINERARY',
        })
        throw error
      })
  }

  const moveItineraryItem = (item, toIdx) => {
    const fromIdx = state.itineraryItems.findIndex((i) => i.id === item.id)
    if (fromIdx < 0) return Promise.reject('Itinerary item not found')
    dispatch({ type: 'LOADING_ITINERARY' })
    console.log('DEBUG MOVINg item from idx pos ', fromIdx, ' to idx ', toIdx)

    return insertItineraryItemToPos(item, toIdx, state.itineraryItems)
      .then((items) => deleteItineraryItemAtPos(fromIdx > toIdx ? fromIdx + 2 : fromIdx, items))
      .then((calculatedItems) => {
        dispatch({
          type: 'UPDATE_ITINERARY_ITEMS',
          payload: calculatedItems,
        })
      })
      .catch((error) => {
        dispatch({
          type: 'OK_ITINERARY',
        })
        throw error
      })
  }

  const setItineraryTripDate = (tripDate) => {
    dispatch({
      type: 'SET_ITINERARY_TRIPDATE',
      payload: tripDate,
    })
  }

  const setItineraryPersons = (persons) => {
    dispatch({
      type: 'SET_ITINERARY_PERSONS',
      payload: persons,
    })
  }

  const setItineraryGratis = (gratis) => {
    dispatch({
      type: 'SET_ITINERARY_GRATIS',
      payload: gratis,
    })
  }

  const setItineraryTeachers = (teachers) => {
    dispatch({
      type: 'SET_ITINERARY_TEACHERS',
      payload: teachers,
    })
  }

  const setItineraryPedagogical = (pedagogical) => {
    dispatch({
      type: 'SET_ITINERARY_PEDAGOGICAL',
      payload: pedagogical,
    })
  }

  const saveItineraryPackage = (params) => {
    console.log('DEBUG SEND params for save', params)
    return fetch(`${config.backend.API_URL}/saved`, {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    }).then((response) => {
      if (!response.ok) throw new Error('Error saving itinerary package')
      return response.json()
    })
  }

  const loadPackageItineraryData = (config, items) => {
    console.log('DEBUG: items should be pulled from DB', items)

    dispatch({
      type: 'LOAD_PACKAGE_DATA',
      config: config,
      itineraryItems: items,
    })
  }

  const setClientData = (obj) => {
    dispatch({
      type: 'SET_CLIENT_DATA',
      payload: obj,
    })
  }

  const requestItineraryOffer = (obj) => {
    console.log('DEBUG SEND params for offer request', obj)
    return fetch(`${config.backend.API_URL}/offer`, {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj),
    })
      .then((response) => {
        if (!response.ok) throw new Error('Error sending offer request')
        return response.json()
      })
      .catch((e) => {
        throw e
      })
  }

  const getItineraryItemsListTextArray = () => {
    const items = state.itineraryItems
      .filter(
        (item) =>
          item.type === 'point' ||
          (item.type === 'transport' && item.data.lunch === true) ||
          (item.type === 'transport' && item.data.break === true)
      )
      .map((item) =>
        item.type === 'transport' ? (item.data.lunch ? 'lunch' : 'break') : item.data.name
      )
    return items
  }

  return (
    <ItineraryContext.Provider
      value={{
        itineraryStatus: state.itineraryStatus,
        itineraryItems: state.itineraryItems,
        recalculateAllRoutes,
        addItineraryItem,
        removeItineraryItem,
        setLunchItem,
        unsetLunchItem,
        setBreakItem,
        unsetBreakItem,
        moveItineraryItem,
        itineraryConfig: state.itineraryConfig,
        setItineraryTripDate,
        setItineraryPersons,
        setItineraryGratis,
        setItineraryTeachers,
        setItineraryPedagogical,
        startItinerary,
        saveItineraryPackage,
        loadPackageItineraryData,
        clientData: state.clientData,
        setClientData,
        requestItineraryOffer,
        getItineraryItemsListTextArray,
      }}
    >
      {children}
    </ItineraryContext.Provider>
  )
}
