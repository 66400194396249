import React from 'react'

import { Pane, Heading, Paragraph, Strong, Button, toaster } from 'evergreen-ui'

export const ErrorInline = ({ msg, error, retry, width }) => {
  console.log('> ErrorInline', error)

  const errorTitle = () => {
    if (error && error.name && error.name !== '') return error.name
  }

  const errorMessage = () => {
    if (msg && msg !== '') return msg
    if (error && error.message && error.message !== '') return error.message
    return 'nepoznata greška'
  }

  return (
    <Pane padding={8} background="redTint" width={width}>
      <Heading size={500} color="#f00">
        Greška!
      </Heading>
      <Paragraph>
        <Strong>{errorTitle()}</Strong>
        <br />
        {errorMessage()}
      </Paragraph>
      {retry ? (
        <Paragraph>
          <Button appearance="primary" intent="danger" onClick={retry}>
            pokušaj ponovo
          </Button>
        </Paragraph>
      ) : (
        <Paragraph>Provjerite internet konekciju i refreshajte stranicu.</Paragraph>
      )}
    </Pane>
  )
}

export const errorToast = (title, description, error) => {
  console.log('ERROR toaster called for error: ', error)

  const errorTitle = () => {
    if (title) return title
    if (error && error.name && error.name !== '') return error.name
    return 'Greška!'
  }

  const errorMessage = () => {
    if (description) return description
    if (error && error.message && error.message !== '') return error.message
    return 'nepoznata greška'
  }

  toaster.danger(errorTitle(), { description: errorMessage() })
}
