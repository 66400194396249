import React, {useContext, Suspense} from 'react'
import { Pane } from 'evergreen-ui'
import { ProvidersContext } from '../context/ProvidersState'
import { ItineraryContext } from '../context/ItineraryState'
import { OverlaySpinner } from './OverlaySpinner'
const Providers = React.lazy(() => import ('./Providers'))
const Itinerary = React.lazy(() => import ('./Itinerary'))


const MainAppWrapper = () => {
  console.log('> MainAppWrapper')

  const { providersConfig } = useContext(ProvidersContext)
  const { itineraryItems } = useContext(ItineraryContext)
  
  if (itineraryItems.length === 0 || providersConfig.startLocation === '') return null

  return (
    <Pane display="flex" flexWrap="wrap" position="relative" minHeight={368}>
      <Suspense fallback={<OverlaySpinner minHeight={368}/>}>
        <Providers />
        <Itinerary />
      </Suspense>
    </Pane>
  )
}

export default MainAppWrapper
