import React, { useState, useEffect, useContext } from 'react'

import 'lite-youtube-embed/src/lite-yt-embed.css'
import 'lite-youtube-embed/src/lite-yt-embed.js'

import './App.css'

import { defaultTheme, ThemeProvider, Pane, Heading, Small } from 'evergreen-ui'
import { v4 as uuidv4 } from 'uuid'

import { ProvidersContext, ProvidersProvider } from './context/ProvidersState'
import { ItineraryContext, ItineraryProvider } from './context/ItineraryState'
import { ConfigContext, ConfigProvider } from './context/ConfigState'
import { DraggableProvider } from './context/DraggableState'

import { OverlaySpinner } from './components/OverlaySpinner'
import { Start } from './components/Start'
import MainAppWrapper from './components/MainAppWrapper'
import { Banner } from './components/Banner'
import { createPortal } from 'react-dom'
// import { Banner } from './components/Banner'

// Logging disable on production
if (process.env.REACT_APP_STAGE === 'prod') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

// Theme customization
const eklataTheme = {
  ...defaultTheme,
  fontFamilies: {
    emphasized:
      '"Poppins", "SF UI Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    display:
      '"Poppins", "SF UI Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    ui: '"Poppins", "SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace',
    mono: '"SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
}
eklataTheme.components.Heading.baseStyle.fontFamily = eklataTheme.fontFamilies.emphasized
eklataTheme.components.Input.baseStyle.fontSize = eklataTheme.fontSizes[3]
eklataTheme.components.Input.appearances.default.border = '1px solid'
eklataTheme.components.Input.appearances.default.borderColor = '#c1c4d6'
eklataTheme.colors.dark = '#363636'
eklataTheme.colors.blue600 = '#005fab' //default buttons hover bgcolor
eklataTheme.colors.blue500 = '#0075C9' //default buttons and ui color
eklataTheme.colors.blue100 = '#e4e3e3' //default disabled button bgcolor
eklataTheme.colors.blue50 = '#cce5fe' //default light blue background
eklataTheme.colors.green500 = '#6BA43A' //"success" related green
eklataTheme.colors.green400 = '#A6D081' //"success" related green
eklataTheme.colors.green300 = '#6ba43a' //"success" related green
eklataTheme.colors.gray700 = '#c8c8c8' //neutrl link color
eklataTheme.colors.greenTint = '#EBF7FF' // default light green background
eklataTheme.colors.orangeTint = '#FAFBFF' // default light green background
console.log('DEBUG theme', defaultTheme, eklataTheme)

const getPackageIDfromURL = () => {
  const pathSplit = window.location.pathname.split('/')
  if (pathSplit.length < 3 || pathSplit[1] !== 'i') return null
  return pathSplit[2]
}

const saveLandingUrl = () => {
  window.izlethr_landing_url = window.location.href
}

const topBannerEl = document.getElementById('topBanner')

const App = () => {
  console.log('>App')
  const [appStatus, setAppStatus] = useState(getPackageIDfromURL() ? 'loading' : 'ok')
  saveLandingUrl()
  console.log('DEBUG lazyload APPSTATUS', appStatus)

  return (
    <ThemeProvider value={eklataTheme}>
      <ProvidersProvider>
        <ItineraryProvider>
          <ConfigProvider>
            <DraggableProvider>
              <Pane minWidth={320} maxWidth={1200} margin="auto">
                {createPortal(
                  <Banner
                    zoneName={window.screen.width < 1200 ? 'header_mob' : 'header_desk'}
                    reloadEverySeconds={300}
                    target="revive"
                    debounce={50}
                  />,
                  topBannerEl
                )}

                <Pane marginBottom={8}>
                  {appStatus !== 'ok' ? (
                    <ItineraryPackageLoader setAppStatus={setAppStatus} />
                  ) : (
                    <Start />
                  )}
                </Pane>
                {appStatus === 'ok' ? <MainAppWrapper /> : null}
              </Pane>
            </DraggableProvider>
          </ConfigProvider>
        </ItineraryProvider>
      </ProvidersProvider>
    </ThemeProvider>
  )
}

const ItineraryPackageLoader = ({ setAppStatus }) => {
  console.log('>ItineraryPackageLoader')

  const { fetchPackage } = useContext(ConfigContext)
  const { loadPackageItineraryData, recalculateAllRoutes } = useContext(ItineraryContext)
  const { fetchRegions, fetchProvidersForRegion, loadPackageProvidersData } =
    useContext(ProvidersContext)

  const [status, setStatus] = useState('loading')

  useEffect(() => {
    // fetch package data
    fetchPackage(getPackageIDfromURL())
      // fetch regions and check if region still exists
      .then((packageData) => {
        console.log('Got package: ', packageData)
        return fetchRegions().then((regions) => {
          if (regions === false) throw new Error('error fetching regions')
          if (!regions.includes(packageData.data.providersConfig.region))
            throw new Error('package region not available!')
          return packageData
        })
      })
      // fetch providers for region and check if all still exist while adding missing data from db to package
      .then((packageData) => {
        return fetchProvidersForRegion(packageData.data.providersConfig.region).then(
          (providers) => {
            if (providers === false) throw new Error('error fetching providers')
            const providerIds = providers.map((i) => i.id)
            packageData.data.itineraryItems = packageData.data.itineraryItems.map((i) => {
              if (i.type !== 'point') return i
              if (!providerIds.includes(i.data.id))
                throw new Error('one or more providers not available')
              return {
                id: uuidv4(),
                data: providers.find((p) => p.id === i.data.id),
                type: i.type,
              }
            })
            return packageData
          }
        )
      })
      // refetch all route calculations
      .then((packageData) => {
        // console.log('DEBUG what is sent to recalculateAllRoutes',packageData.data.itineraryItems)
        return recalculateAllRoutes(packageData.data.itineraryItems).then(
          (recalculatedItineraryitems) => {
            packageData.data.itineraryItems = recalculatedItineraryitems
            return packageData
          }
        )
      })
      // update Contexts, update app and component statuses
      .then((packageData) => {
        loadPackageProvidersData(packageData.data.providersConfig)
        loadPackageItineraryData(packageData.data.itineraryConfig, packageData.data.itineraryItems)
        return packageData
      })
      .then(() => {
        console.log('---------------------------------- package loaded ------------------')
        setStatus('ok')
        setAppStatus('ok')
      })
      .catch((error) => {
        setStatus({ error: true, errorObj: error })
        console.log('ERROR loading package:', error)
      })
  }, [])

  if (status.error)
    return (
      <Pane height={400} background={'white'} padding={16}>
        <Heading>Dogodila se pogreška prilikom učitavanja itinerara.</Heading>
        <Small>{status.errorObj.message || 'unknown error'}</Small>
      </Pane>
    )

  return (
    <Pane height={400} position="relative">
      {status === 'loading' ? <OverlaySpinner /> : null}
    </Pane>
  )
}

export default App
