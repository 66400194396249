import React, { createContext, useReducer } from 'react'

import config from '../config'
import providersReducer from './ProvidersReducer'

const initialState = {
  providersStatus: 'loading',
  regionsStatus: 'loading',
  countiesStatus: 'loading',
  providerItems: [],
  providersConfig: {
    category: [],
    county: [],
    region: '',
    startAddress: '', //typed by user
    startLocation: '', //calculated by map provider
    startCity: '', //calculated by map provider
  },
  containerHeight: 0,
  providersRegions: [],
  providersCounties: [],
}

export const ProvidersContext = createContext(initialState)

export const ProvidersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(providersReducer, initialState)

  const fetchProvidersForRegion = (region) => {
    dispatch({ type: 'LOADING_PROVIDERS' })
    return fetch(`${config.backend.API_URL}/providers?region=${region}`)
      .then((response) => {
        if (!response.ok) throw new Error('error fetching providers')
        if (response.length) throw new Error('no providers found')
        return response.json()
      })
      .then((data) => {
        if (data.length === 0) throw new Error('no services found')
        dispatch({ type: 'UPDATE_PROVIDERS', payload: data })
        return data
      })
      .catch((error) => {
        console.log('ERROR! ', error)
        dispatch({ type: 'PROVIDERS_ERROR', payload: error })
        return false
      })
  }

  const updateRegion = (region) => {
    dispatch({ type: 'UPDATE_REGION', region: region })
  }

  const clearProviders = () => {
    dispatch({ type: 'CLEAR_PROVIDERS' })
  }

  const fetchRegions = () => {
    console.log('fetching regions...')
    dispatch({ type: 'LOADING_REGIONS' })
    return fetch(`${config.backend.API_URL}/regions`)
      .then((response) => {
        if (!response.ok) throw new Error('error fetching regions')
        return response.json()
      })
      .then((data) => {
        dispatch({ type: 'UPDATE_REGIONS', payload: data })
        return data
      })
      .catch((error) => {
        console.log('ERROR! ', error)
        dispatch({ type: 'REGIONS_ERROR', payload: error })
        return false
      })
  }

  const fetchCounties = () => {
    console.log('fetching counties...')
    dispatch({ type: 'LOADING_COUNTIES' })
    return fetch(`${config.backend.API_URL}/counties`)
      .then((response) => {
        if (!response.ok) throw new Error('error fetching counties')
        return response.json()
      })
      .then((data) => {
        console.log('got counties:', data)
        dispatch({ type: 'UPDATE_COUNTIES', payload: data })
        return data
      })
      .catch((error) => {
        console.log('ERROR! ', error)
        dispatch({ type: 'COUNTIES_ERROR', payload: error })
        return false
      })
  }

  const geolocatePlace = (place) => {
    console.log('getting location for the place...', place)
    return fetch(`${config.backend.API_URL}/geolocation?q=${place}`)
      .then((response) => {
        if (!response.ok) throw new Error('error fetching location')
        return response.json()
      })
      .catch((error) => {
        console.log('ERROR! ', error)
        return { error: true, errorObj: error }
      })
  }

  const updateStartLocation = (address, coords, city) => {
    dispatch({
      type: 'UPDATE_START_LOCATION',
      payload: {
        startAddress: address,
        startCity: city,
        startLocation: coords,
      },
    })
  }

  const updateCategoryAdd = (category) => {
    if (Array.isArray(category)) {
      dispatch({ type: 'UPDATE_CATEGORY_SELECT_ALL', categories: category })
    } else {
      dispatch({
        type: 'UPDATE_CATEGORY_ADD',
        category: category,
      })
    }
  }

  const updateCategoryRemove = (category) => {
    dispatch({
      type: 'UPDATE_CATEGORY_REMOVE',
      category: category,
    })
  }

  const updateCountyAdd = (county) => {
    if (county === 'SVE') {
      let activeCounties = state.providersCounties.filter((county) => {
        const nrItemsInCounty = state.providerItems.filter((item) => item.county === county).length
        return nrItemsInCounty > 0
      })
      console.log('DEBUG SVE: ', activeCounties)
      dispatch({
        type: 'UPDATE_COUNTY_SELECT_ALL',
        activeCounties: activeCounties
      })
    } else {
      dispatch({
        type: 'UPDATE_COUNTY_ADD',
        county: county,
      })
    }
  }

  const updateCountyRemove = (county) => {
    dispatch({
      type: 'UPDATE_COUNTY_REMOVE',
      county: county,
    })
  }
  const loadPackageProvidersData = (data) => {
    dispatch({
      type: 'LOAD_PACKAGE_DATA',
      data: data,
    })
  }

  const setContainerHeight = (obj) => {
    console.log('DEBUG SET itinerary container height to: ', obj)
    dispatch({
      type: 'SET_HEIGHT',
      payload: obj,
    })
  }

  return (
    <ProvidersContext.Provider
      value={{
        providersStatus: state.providersStatus,
        regionsStatus: state.regionsStatus,
        containerHeight: state.containerHeight,
        countiesStatus: state.countiesStatus,
        providerItems: state.providerItems,
        providersConfig: state.providersConfig,
        fetchProvidersForRegion,
        providersRegions: state.providersRegions,
        fetchRegions,
        providersCounties: state.providersCounties,
        fetchCounties,
        geolocatePlace,
        updateStartLocation,
        updateCategoryAdd,
        updateCategoryRemove,
        updateCountyAdd,
        updateCountyRemove,
        updateRegion,
        clearProviders,
        loadPackageProvidersData,
        setContainerHeight,
      }}
    >
      {children}
    </ProvidersContext.Provider>
  )
}
