export default function configReducer(state, action) {
  switch (action.type) {
    case 'LOADING_CONFIG':
      return {
        ...state,
        status: 'loading',
      }

    case 'ERROR':
      return {
        ...state,
        status: action.payload.error,
      }

    case 'UPDATE_CONFIG':
      const configFromDb = Object.fromEntries(action.payload.map((item) => [item.name, item.value])) //eslint-disable-line
      //delete empty categries from config
      for (var key in configFromDb.categories) {
        if (Object.prototype.hasOwnProperty.call(configFromDb.categories, 'key')) {
          if (configFromDb.categories[key] === null || !configFromDb.categories[key])
            delete configFromDb.categories[key]
        }
      }
      configFromDb.transport = configFromDb.transport.filter((i) => i.seats && i.seats != '0')

      return {
        ...state,
        ...configFromDb,
        status: 'ok',
      }

    default:
      return state
  }
}
