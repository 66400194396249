import React, { useEffect } from 'react'

import { Pane, Spinner, Text, Strong } from 'evergreen-ui'

// wrapper for Spinner to display it inside a parent Pane/div as centered
// semitransparent OverlaySpinner, without resizing parent size
// NOTE: parent needs to have `position: relative` style set

export const OverlaySpinner = ({ message, delay = 300 }) => {
  const [show, setShow] = React.useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true)
    }, delay)
    return () => {
      console.log('CELEANING TIMEOUT')
      clearTimeout(timeout)
    }
  }, [show])

  if (!show) return null

  return (
    <Pane
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor="rgba(255,255,255,0.8)"
      style={{
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 99,
      }}
    >
      <Spinner />
      {message ? (
        <Text>
          <Strong>{message}</Strong>
        </Text>
      ) : null}
    </Pane>
  )
}
