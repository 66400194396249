/*
 *  Sitewide helper functions
 */

export const isNonNegativeInteger = (val) => {
  if (typeof val !== 'number' && typeof val !== 'string') return false

  const num = Number(val)
  if (Number.isInteger(num) && num >= 0) return true
  return false
}

export const cents2eur = (cents) => {
  const eur = parseFloat(cents / 100)
  return isNaN(eur) ? '' : `${roundMoney(eur)} EUR`
}

export const cents2kn = (cents) => {
  const eur = roundMoney(parseFloat(cents / 100))
  return isNaN(eur) ? '' : `${roundMoney(eur * 7.5345)} Kn`
}

export const roundMoney = (amount) => Math.round(amount*100)/100

export const getCrowFlyDistance = (point1, point2) => {
  const [lat1, lon1] = point1.split(',')
  const [lat2, lon2] = point2.split(',')

  const R = 6371e3 // metres
  const φ1 = (lat1 * Math.PI) / 180 // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180
  const Δφ = ((lat2 - lat1) * Math.PI) / 180
  const Δλ = ((lon2 - lon1) * Math.PI) / 180

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return R * c
}

// NOTE: Function optimizes number of passengers across available options of seats in buses
// Algorythm prioritizes minimal number of busses, second priority is smalles possible buses within this minimal number of them (least empty seats). Algorythm assumes that those 2 rules end up being the cheapest
//   ... it is way more complex to be 100% sure this is the cheapest option without:
//       - taking into account bus prices for autoday AND per-km (but larger buses are cheaper per person anyway)
//       - taking into account highway/tunnel fees for different bus categories (same as above, larger = cheaper)
//       - taking into account that more busses require more dirvers which affect other related costs of the trip (same as obove, larger busses, less drivers)
//   ... that's why I feel this simplified solution is also cheapest solution in 99% of cases if not 100%. Practice will tell.
// Note that we need to reserve one seat for a guide in each bus. that's why +1/-1 compensations below.

// Old algorythim with error (i.e. For 150 pax return 70+70+50 instead 50+50+50)
// export const getOptimalBuses = (seatOptions, passengers, buses = []) => {
//   if (seatOptions.length == 0) throw new Error('no choices')
//   let larger = seatOptions[0] - 1 //leave one space for a guide... same below
//   if (passengers <= 0) {
//     return [buses, Math.abs(passengers)]
//   } else if (passengers > larger || seatOptions.length == 1) {
//     buses.push(larger + 1)
//     passengers -= larger + 1
//   } else if (seatOptions.length > 1 && passengers > seatOptions[1] - 1) {
//     buses.push(larger + 1)
//     passengers -= larger + 1
//     seatOptions.shift()
//   } else {
//     seatOptions.shift()
//   }
//   return getOptimalBuses(seatOptions, passengers, buses)
// }

// NEW algorythm (v2)
export const getOptimalBuses = (seatOptions, passengers) => {
  if (!seatOptions || seatOptions.length == 0) throw new Error('no choices')
  seatOptions = seatOptions.map(str => Number(str))

  let i
  // only one 59er can be rented!
  if (seatOptions[0] === 59) {
    const secondBusSeats = seatOptions[1] ? seatOptions[1] : seatOptions[0]
    i = 1 + Math.ceil((passengers - 58) / (secondBusSeats - 1)) //disregard 1 seat reserved for 1 guide per bus
  } else {
    i = Math.ceil(passengers / (seatOptions[0] - 1)) //disregard 1 seat reserved for 1 guide per bus
  }

  const combinations = (i, prev) => {
    if (!prev) prev = seatOptions.map((seats) => [seats])
    if (i === 1) return prev

    let result = []
    let cur = combinations(i - 1, prev)

    cur.forEach((item) => {
      seatOptions.forEach((seats) => {
        const last = item[item.length - 1]
        if (last >= seats && seats != 59) {  //onyl one 59-er can be rented!
          result.push(item.concat(seats))
        }
      })
    })

    return result
  }

  let allCombinations = combinations(i)
  let bestCombination = []
  let minSeats = Infinity
  allCombinations.forEach((combo) => {
    const totalSeats = combo.reduce((sum, seats) => sum + seats - 1, 0) //disregard 1 seat reserved for 1 guide per bus
    if (totalSeats >= passengers && totalSeats < minSeats) {
      minSeats = totalSeats
      bestCombination = combo
    }
  })
  return [bestCombination, minSeats - passengers]
}



// NOTE: Function calculates extra gratises given by the agency based on number of pupils
// It is defined by the client and basically is:
//   - 1.5 grt for 0-45 pupils
//   - 46th pupil gets extra 1.5 grt
//   - for every 25 pupils above 46 another 1.5 grt
export const getGratisesFromAgency = (pupils) => {
  if (pupils < 46) return 1.5
  return 1.5 + Math.floor((pupils - 45) / 25) * 1.5
}

export const nearest_multiple_of = (N, x) => {
  return x + ((N - (x % N)) % N)
}

export const makeid = (length) => {
  let result           = ''
  let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let charactersLength = characters.length
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const fireTagManagerEvent = (object) => {
  if (process.env.REACT_APP_STAGE === 'prod') { //don't send gtag manager events on testing
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(object)
  }
  console.log('Pushed gTagManager event: ', object)
}
